.header {
  text-align: center;
  font-family: 'Boldonse', system-ui;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
  color: #4b00b3;
}

.candidateList {
  padding: 5px;
  margin: 0;
  font-size: 0.8rem;
}
